import { EntitlementMeta, useUpsell } from '@/hooks/useUpsell'
import { breakpoints } from '@/styles/breakpoints'
import { ButtonLabel, HeadingLabel, PrimaryLabel } from '@/styles/fonts'
import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import { ComponentVariantContext } from '@grandstand-web/bally-web-shared/src/context/VariantContexts'
import { isVideoCard } from '@grandstand-web/bally-web-shared/src/utils/entitlementUtils'
import { getStaticPageUrl } from '@grandstand-web/bally-web-shared/src/utils/staticPageUtils'
import Player from '@grandstand-web/player/src/components/Player'
import {
    Card,
    NavigateAction,
    RemoteImage,
    VideoCard,
    VideoStatus,
    WatchVideoRail,
} from '@grandstand/presentation-models'
import Image from 'next/image'
import { useContext, useState } from 'react'
import styled from 'styled-components'
import { GrandstandButton } from '../Buttons'
import { BaseContainer } from '../containers/BaseContainer'
import { ComponentRenderer } from './ComponentRenderer'
import { textForLabel } from './Label'

const RailWrapper = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
`

const RailItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  overflow: hidden;
  & > * {
    flex: 0;
  }

  @media screen and (max-width: 768px) {
    overflow-x: scroll;
    padding-bottom: 8px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
/* OnImage - Tried to keep this as general as possible for prosperity */
const OnImage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  height: auto;
`
const Background = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  object-fit: cover;
  overflow: hidden;
`
const BackgroundImage = styled(Image)`
  position: relative;
  display: block;
  z-index: 1;
  object-fit: cover;
`
const VideoWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 1s;

  .bitmovinplayer-wrapper,
  .bitmovinplayer-container {
    &,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const BackgroundImageOverlay = styled.div`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  z-index: 2;
`
const Foreground = styled.div`
  position: relative;
  z-index: 2;
`
/* END OnImage */

const HeroOnImage = styled(OnImage)`
  /* Desktop styles */
  @media screen and (min-width: 769px) {
    position: static;
    aspect-ratio: unset;
    object-fit: initial;
    overflow: visible;
  }
`
const DesktopHeroOnImage = styled(OnImage)`
  gap: 48px;
  @media screen and (max-width: 768px) {
    position: static;
    object-fit: initial;
    overflow: visible;
  }
`

const MetaForeground = styled(Foreground)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 28.125% 48px 28.125%;
  width: 100%;
  @media screen and (max-width: 768px) {
    justify-content: flex-end;
    padding: 24px;
    aspect-ratio: 16/9;
  }
  @media screen and (max-width: 375px) {
    padding: 16px;
  }
`

const DesktopForeground = styled(MetaForeground)`
  padding: 0 48px 48px;
  margin-top: -28.125%;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding: 0;
    aspect-ratio: auto;
  }
`
const Status = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  @media screen and (min-width: 375px) {
    gap: 4px;
  }
`
const StatusLabel = styled(PrimaryLabel)`
  text-transform: uppercase;
  color: var(--on-background);
  ${breakpoints.up('md')} {
    color: #ffffff;
  }
`

const LiveIndicator = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--live, rgba(236, 0, 0, 1));
`

const HeroButton = styled(GrandstandButton)`
  display: inline-flex;
  align-items: stretch;
  text-transform: uppercase;
  gap: 8px;
  align-self: flex-start;
  & > * {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    gap: 4px;
    padding: 8px;
  }
  @media screen and (max-width: 375px) {
    gap: 4px;
    padding: 8px;
  }
`

const HeroHeadingLabel = styled(HeadingLabel)`
  color: var(--white);
`

export const WatchVideoRailView = ({ component }: { component: WatchVideoRail }) => {
  const baseVariant = useContext(ComponentVariantContext)

  const {
    items,
    meta: {
      items_info: { variant },
    },
    content: { streams },
  } = component

  const [streamIndex] = useState<number>(0)
  const [isVideoReady, setIsVideoReady] = useState(true)
  const stream = streams[streamIndex]
  const { status, title, thumbnail } = stream

  const cardForStream: Card = items[streamIndex] as Card
  const meta: EntitlementMeta | undefined = cardForStream.meta
  const cardForStreamImageSrc: string = isVideoCard(cardForStream)
    ? ((cardForStream as VideoCard).content?.hero_image as RemoteImage | undefined)?.link.url ?? thumbnail
    : thumbnail

  const {
    content: { action, video },
  } = cardForStream as VideoCard
  // Grab the neighborindIds of videos in rail for live-events/vod-page
  const parsedUrl = new URL(action.link.url)
  const neighboringIds = parsedUrl.searchParams.get('ids')
  const staticPageUrl = getStaticPageUrl(action as NavigateAction, video?.id as string, neighboringIds as string)

  const { goToEntitled } = useUpsell({
    contentUrl: staticPageUrl,
    meta: cardForStream.meta,
  })
  const handleStreamOnClick = () => {
    if (stream?.status === VideoStatus.upcoming) {
      return
    }
    goToEntitled()
  }

  return (
    <RailWrapper padded={true}>
      <DesktopHeroOnImage>
        <HeroOnImage>
          <Background>
            <BackgroundImage
              fill
              src={cardForStreamImageSrc}
              priority
              alt=""
              sizes="(max-width: 1728px) 100vw, 1632px"
              aria-hidden={true}
            />
            {video && (
              <VideoWrapper show={isVideoReady}>
                <Player
                  isBackgroundVideo={true}
                  videoId={video.id}
                  chromeless={true}
                  muted={true}
                  shouldRenderErrorOverlay={false}
                  shouldRenderLoadingSpinner={false}
                  shouldTrackVideoAnalytics={false}
                  onPlayerCanPlayThrough={() => {
                    setIsVideoReady(true)
                  }}
                />
              </VideoWrapper>
            )}
            <BackgroundImageOverlay />
          </Background>
          <MetaForeground>
            <HeroHeadingLabel fontSize="6" tabletFontSize="4" mobileFontSize="2">
              {title}
            </HeroHeadingLabel>
            <HeroButton
              buttonSize="md"
              buttonStyle={status === VideoStatus.live ? 'live' : 'primary'}
              onClick={handleStreamOnClick}
            >
              <GrandstandIcon width="18px" state="live" icon="player_controls_play" />
              <ButtonLabel fontSize="md" mobileFontSize="xs" tabletFontSize="sm">
                Watch Now
              </ButtonLabel>
            </HeroButton>
          </MetaForeground>
        </HeroOnImage>
        <DesktopForeground>
          <Status>
            <StatusLabel fontSize="md" tabletFontSize="sm" mobileFontSize="xs">
              {textForLabel(component.content.header?.label)}
            </StatusLabel>
          </Status>
          <ComponentVariantContext.Provider value={{ variant: variant ?? baseVariant.variant, size: 'wide' }}>
            <RailItemsWrapper>
              {items &&
                items.map((item) => {
                  return <ComponentRenderer key={item.id} component={item}></ComponentRenderer>
                })}
            </RailItemsWrapper>
          </ComponentVariantContext.Provider>
        </DesktopForeground>
      </DesktopHeroOnImage>
    </RailWrapper>
  )
}
